.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.auth-container {
  box-shadow: 0px 0px 8px #00000026;
  border-radius: 20px;
  min-width: 300px;
  padding: 30px 60px 30px 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.auth-label {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
}

.auth-label-spam {
  font-family: "Urbanist";
  font-size: 12px;
  font-weight: 300;
  color: #000000;
}

.auth-label-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.auth-login-btn {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 14px;
  width: 100%;
}

.auth-login-btn:hover {
  background-color: #000000d1;
}

.auth-bottom-nav {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  cursor: pointer;
}

.auth-error-msg {
  font-family: "Urbanist";
  font-size: 12px;
  font-weight: 400;
  color: #ff0000;
}

.auth-success-msg {
  font-family: "Urbanist";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.auth-signup-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 600px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.top-app-bar-text {
  font-family: "Urbanist";
  font-weight: 300;
  color: #000000;
  text-decoration: none;
  font-size: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* App.css */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeInAnimation {
  animation: fadeIn 2s ease-in-out forwards;
}

.home-header {
  font-family: "Urbanist";
  font-weight: 200;
  color: #000000;
  text-decoration: none;
  font-size: 3rem;
  animation: fadeIn 2s ease-in-out forwards;
}

.home-top {
  background-color: #ffffff;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.top-app-bar {
  font-size: 16px !important;
  font-family: "Urbanist" !important;
  font-weight: 200 !important;
}

.top-app-bar:hover {
  background-color: white !important;
}

@media only screen and (max-width: 600px) {
  .auth-container {
    width: 100% !important;
    padding: 40px !important;
    height: auto !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .auth-signup-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
  }

  .home-header {
    font-size: 1.6rem;
  }

  .home-jobs {
    padding: 0px !important;
  }

  .top-app-bar-text {
    font-size: 14px;
  }

  .search-bar {
    width: auto !important;
    flex-direction: column;
  }
  .search-bar input[type="text"] {
    width: auto !important;
    font-size: 14px;
  }

  .search-bar button {
    padding: 10px 20px;
    margin-top: 10px;
  }

  .home-top {
    margin-top: 100px;
  }

  .jobs-outer-container {
    height: 100% !important;
    width: 100% !important;
    display: flex !important;
    margin-top: 40px !important;
  }

  .jobs-container {
    flex-direction: column !important;
    align-content: center !important;
    padding-top: 30px !important;
    margin-top: 0px !important;
  }

  .filters-container {
    display: none !important;
  }

  .jobs-outer-container .header {
    flex-direction: column !important;
    justify-content: center !important;
    width: auto !important;
    gap: 20px;
    margin-bottom: 40px;
  }

  .job-view-container {
    margin-top: 10px !important;
  }

  .job-view {
    width: auto !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    height: auto !important;
    gap: 20px !important;
  }

  .back-to-jobs-container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .job-view .title {
    font-size: 16px !important;
  }

  .job-view .job-details-container {
    display: flex;
    flex-direction: column !important;
    gap: 10px !important;
    height: auto;
  }

  .job-view .job-location-container {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .job-view .job-location {
    font-size: 12px !important;
  }

  .job-view .job-date-container {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .job-view .job-date {
    font-size: 12px !important;
  }

  .job-view .job-type-container {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .job-view .job-chip {
    font-size: 10px !important;
    font-family: "Urbanist";
    font-weight: 400;
    color: black;
    padding: 5px 10px 5px 10px;
  }

  .job-view .description {
    font-family: "Urbanist";
    font-size: 20px;
    font-weight: 400;
    color: rgb(112, 112, 112);
  }

  .job-view .description-text {
    font-family: "Urbanist";
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: rgb(112, 112, 112);
  }

  .pricing .card-container {
    flex-direction: column !important;
    gap: 40px !important;
    padding-bottom: 40px !important;
  }

  .aboutus {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    width: 100% !important;
    margin-top: 40px;
  }

  .aboutus .title {
    font-family: "Urbanist";
    font-size: 40px;
    font-weight: 600;
    color: #000000;
    text-align: center !important;
    width: 100% !important;
  }

  .aboutus .content {
    font-family: "Urbanist";
    font-size: 14px !important;
    font-weight: 300;
    color: #000000;
    letter-spacing: 3px !important;
    line-height: 1.4rem !important;
    width: 100% !important;
  }

  .aboutus .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    gap: 40px;
    width: 320px !important;
    flex-wrap: wrap;
  }

  .switch {
    font-size: 12px !important;
    margin-top: 10px !important;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px); /* Start from below its original position */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move to its original position */
  }
}

/* src/SearchBar.css */
.search-bar {
  position: relative;
  bottom: -50px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 20px;
  box-shadow: 0px 0px 8px #00000026;
  border-radius: 20px;
  width: 850px;
  padding: 10px 10px 10px 10px;
  animation: fadeInUp 2s ease-in-out forwards;
}

.search-bar input[type="text"] {
  padding: 10px;
  width: 40%;
  border: none;
  border-radius: 5px;
  font-family: "Urbanist";
  font-size: 16px;
}

.search-bar input[type="text"]:focus {
  outline: none; /* Removes the default focus outline */
  border: none; /* Makes the border transparent on focus */
  /* Add any other styling you want to maintain or change on focus */
}

.search-bar button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
}

.search-bar button:hover {
  background-color: #000000d1;
}

.jobs-outer-container {
  height: 100%;
  width: 1200px;
  display: flex;
  margin-top: 40px;
  gap: 60px;
}

.filters-container {
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 220px;
}

.jobs-container {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  gap: 30px;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  padding-bottom: 30px;
  background-color: #fafbfc;
}

.jobs-outer-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 878px;
  margin-top: 20px;
}

.jobs-outer-container select {
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 300;
}

.jobs-outer-container .job-count {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 300;
  color: #000000;
}

.no-jobs-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  gap: 20px;
}

.no-jobs-wrapper button {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
}

.no-jobs-wrapper button:hover {
  background-color: #000000d1;
}

.no-jobs-found {
  font-family: "Urbanist";
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  margin-top: 20px;
}

.home-jobs {
  flex-grow: 1;
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-jobs .job {
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #00000026;
  border-radius: 20px;
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  padding: 26px;
  align-items: flex-start;
  justify-content: space-between;
}

.job {
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #00000026;
  border-radius: 20px;
  width: 220px;
  height: 240px;
  display: flex;
  flex-direction: column;
  padding: 26px;
  align-items: flex-start;
  justify-content: space-between;
}

.dashboard-jobs .job {
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #00000026;
  border-radius: 20px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  padding: 26px;
  align-items: flex-start;
  justify-content: space-between;
}

.dashboard-jobs .job .job-name {
  font-family: "Urbanist";
  font-size: 20px !important;
  font-weight: 600;
  font-size: 14px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.job .promoted {
  padding: 4px 7px 4px 7px;
  background-color: #f5f5f5;
  color: #616161;
  border-radius: 20px;
  font-family: "Urbanist";
  font-size: 12px;
  font-weight: 300;
  width: 60px;
  text-transform: capitalize !important;
}

.job .job-name {
  font-family: "Urbanist";
  font-size: 20px !important;
  font-weight: 600;
  font-size: 14px;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.job .job-company-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.job .job-company {
  font-family: "Urbanist";
  font-size: 14px !important;
  font-weight: 200;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.job .job-location-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.job .job-location {
  font-family: "Urbanist";
  font-size: 14px !important;
  font-weight: 200;
  font-size: 14px;
}

.job .job-date-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.job .job-date {
  font-family: "Urbanist";
  font-size: 14px !important;
  font-weight: 200;
  font-size: 14px;
}

.job .job-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 16px;
}

.job .job-type-container {
  display: flex;
  gap: 10px;
  margin-top: 12px;
}

.job .days-left {
  border-radius: 20px;
  font-size: 11px !important;
  font-family: "Urbanist";
  font-weight: 400;
  color: black;
  padding: 5px 10px 5px 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.job .job-chip {
  border-radius: 20px;
  font-size: 11px !important;
  font-family: "Urbanist";
  font-weight: 400;
  color: black;
  padding: 5px 10px 5px 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.job .applicants-btn {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
}

.job .applicants-btn:hover {
  background-color: #000000d1;
}

.jobs-btn {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
}

.jobs-btn:hover {
  background-color: #000000d1;
}
.job .favorite-icon:hover {
  color: #ff7e7ed1;
}

.job .favorite-icon {
  cursor: pointer;
}

.edit-modal-days-chip {
  padding: 8px 15px 8px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  font-family: "Urbanist";
  font-size: 12px;
}

.edit-modal-promo-chip {
  padding: 8px 15px 8px 15px;
  background-color: #f5f5f5;
  color: #616161;
  border-radius: 20px;
  font-family: "Urbanist";
  font-size: 12px;
  font-weight: 300;
  width: 120px;
  text-align: center;
  text-transform: capitalize !important;
}

.edit-modal-btn {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.edit-modal-btn:hover {
  background-color: #000000d1;
}

.new-accordion {
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  font-family: "Urbanist" !important;
  width: 200px;
}

.new-accordion .filter-title {
  font-family: "Urbanist" !important;
  font-size: 14px !important;
  margin-right: 10px;
}

.new-accordion .filter-options {
  font-family: "Urbanist" !important;
  font-size: 12px !important;
}

.back-to-jobs-container {
  gap: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-to-jobs {
  font-family: "Urbanist";
  font-size: 16px;
  position: relative;
  font-weight: 200;
}

.job-view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 1000px;
  background-color: #ffffff;
  border-radius: 20px;
  height: auto;
  padding: 30px;
  box-shadow: 0px 0px 8px #00000026;
}

.job-view-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.job-view .title {
  font-family: "Urbanist";
  font-size: 30px;
  font-weight: 300;
}

.job-view .job-details-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  height: auto;
}

.job-view .job-company-container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.job-view .job-company {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 300;
  color: rgb(112, 112, 112);
}

.job-view .job-location-container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.job-view .job-location {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 300;
  color: rgb(112, 112, 112);
}

.job-view .job-date-container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.job-view .job-date {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 300;
  color: rgb(112, 112, 112);
}

.job-view .job-type-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.job-view .job-chip {
  border-radius: 20px;
  font-size: 12px !important;
  font-family: "Urbanist";
  font-weight: 400;
  color: black;
  padding: 5px 10px 5px 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.job-view .description {
  font-family: "Urbanist";
  font-size: 20px;
  font-weight: 400;
  color: rgb(112, 112, 112);
}

.job-view .description-text {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: rgb(112, 112, 112);
}

.job-view button {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
}

.job-view button:hover {
  background-color: #000000d1;
}

.job-view .applied-button {
  background-color: #393939d1;
}

.job-view .applied-button:hover {
  background-color: #393939d1;
}

.job-view .dialog-title {
  font-family: "Urbanist" !important;
  font-size: 20px !important;
  font-weight: 300 !important;
  color: #000000;
}

.job-view .dialog-content {
  font-family: "Urbanist" !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  color: #000000;
}

.job-view .dialog-container {
  padding: 20px !important;
  display: flex;
  flex-direction: column;
}

.pricing {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: auto;
}

.pricing .btn-wrapper {
  display: flex;
  gap: 10px;
  box-shadow: 0px 0px 8px #00000026;
  border-radius: 20px;
  padding: 10px 15px 10px 15px;
  animation: fadeInUp 2s ease-in-out forwards;
}

.pricing .btn-wrapper button {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 16px;
  background-color: #ffffff;
  color: gray;
  transition: background-color 0.3s;
}

.pricing .btn-wrapper button:hover {
  background-color: #f8f8f8; /* Slightly off-white/grey on hover for subtle effect */
}

.pricing .btn-wrapper button.active {
  background-color: #000000;
  color: white;
}

.pricing .title {
  font-family: "Urbanist";
  font-size: 30px;
  font-weight: 100;
  margin-top: 30px;
}

.pricing .card-container {
  display: flex;
  gap: 20px;
  animation: fadeInUp 2s ease-in-out forwards;
  width: auto;
  align-items: center;
}

.pricing .card-business {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 300px;
  height: 400px;
  padding: 20px;
  box-shadow: 0px 0px 8px #00000026;
}

.pricing .card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 200px;
  height: 140px;
  padding: 20px;
  box-shadow: 0px 0px 8px #00000026;
  justify-content: space-between;
}

.pricing .card-rec {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 200px;
  height: 180px;
  padding: 20px;
  box-shadow: 0px 0px 8px 2px #00000026;
  justify-content: space-between;
}

.pricing .card-business .card-title {
  font-family: "Urbanist";
  font-size: 30px;
  font-weight: 300;
  color: #ffffff;
}

.pricing .card-title-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.pricing .card-title {
  font-family: "Urbanist";
  font-size: 30px;
  font-weight: 500;
  color: #000000;
}

.pricing .card-subtitle {
  font-family: "Urbanist";
  font-size: 20px;
  font-weight: 300;
  color: gray;
}

.pricing .price-option-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.pricing .price-option {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  text-align: left;
  display: flex;
  gap: 10px;
  align-items: center;
}

.pricing .card-business .price-option {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
}

.pricing button {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
}

.pricing .card-business button {
  padding: 10px 15px 10px 15px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .profile .profile-bubble {
    width: 400px !important;
  }

  .profile .bubble {
    width: 400px !important;
  }

  .profile .profile-details {
    width: 100% !important;
  }

  .profile .name {
    font-size: 16px !important;
  }

  .profile .profile-container {
    flex-wrap: wrap;
  }

  .profile-icons-container {
    display: flex;
    position: relative !important;
    left: 252px !important;
  }

  .profile .resume {
    max-width: 350px !important;
  }

  .profile-drawer {
    width: 300px !important;
  }

  /* .dashboard .sidebar {
    display: none !important;
  } */
}

.profile {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.profile-icons-container {
  display: flex;
}

.profile .profile-bubble {
  margin-top: 2rem;
  border-radius: 20px;
  box-shadow: 0px 0px 8px #00000026;
  width: 800px;
  height: auto;
  padding-bottom: 2rem;
  background-color: #ffffff;
}

.profile .top-section {
  height: 200px;
  width: 100%;
  background-image: linear-gradient(to right, #ebebeb, #fffcfe);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.profile .profile-container {
  background: transparent;
  z-index: 2;
  margin-top: -5.4rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.profile .profile-img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 10px solid #ffffff;
}
.profile .img-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 3rem;
}

.profile .avatar {
  height: 150px;
  width: 150px;
}

.profile .avatar-img {
  height: 100px;
  width: 100px;
}
.profile .profile-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding-left: 2rem;
}

.profile .name {
  font-family: "Urbanist";
  font-size: 28px;
  font-weight: 600;
  color: #000000;
}

.profile .location {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
}

.profile .resume {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 750px;
  width: 100%;
}

.profile .title {
  font-family: "Urbanist";
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.profile .body {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
}

.profile .bubble {
  border-radius: 20px;
  box-shadow: 0px 0px 8px #00000026;
  width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: #ffffff;
}

.profile .header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.profile-label {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px;
}

.profile .experience {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  margin-top: 10px;
}

.profile .experience .role {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.profile .experience .company {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  display: flex;
  align-items: center;
}

.profile .experience .location {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  display: flex;
  align-items: center;
}

.profile .experience .dates {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  display: flex;
  align-items: center;
}

.profile .experience .description {
  margin-top: 10px;
  font-family: "Urbanist";
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 1px;
  font-weight: 200;
  color: #000000;
  text-align: left;
}

.profile .certifications {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  margin-top: 10px;
}

.profile .issued-by {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  display: flex;
  align-items: center;
}

.profile .valid-dates {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  display: flex;
  align-items: center;
}

.dropzone {
  border: 2px dashed #000000;
  border-radius: 20px;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  background-color: white;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* Four columns of equal size */
  grid-auto-rows: 200px; /* Each row is 200px tall */
  gap: 6px;
  margin-top: 30px;
  width: 100%;
}

.first-item {
  grid-column: auto; /* Spanning the first 2 columns */
  grid-row: auto; /* Spanning 2 rows */
  width: 100%; /* Adjusted to fill the cell */
  height: 100%;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
  transition: filter 0.1s ease;
  border: #ebebeb 1px solid;
  border-radius: 10px;
}

.other-item {
  grid-column: auto; /* Spanning 1 column */
  grid-row: auto; /* Spanning 1 row */
  width: 100%; /* Adjusted to fill the cell */
  height: 100%;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
  transition: filter 0.1s ease;
  border: #ebebeb 1px solid;
  border-radius: 10px;
}

.image-item {
  height: auto;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
  max-height: 120px;
}

.first-item .image-item {
  width: 150px; /* Width for first-item */
}

.other-item .image-item {
  width: 150px; /* Width for other-item */
}

/* Style for the container to allow smooth reordering of other items */
.grid-container .other-item {
  transition: transform 0.1s ease-out;
}

/* Style for the container to allow smooth reordering of other items */
.grid-container .first-item {
  transition: transform 0.1s ease-out;
}

.image-wrapper {
  position: relative;
  display: grid;
  place-items: center;
  height: 100%;
  max-height: 150px;
  /* ... your other styles ... */
}

.checkbox-container {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 2; /* Ensures the checkbox is above the image */
  opacity: 0; /* Hide the checkbox by default */
  transition: opacity 0.2s ease; /* Smooth transition for the checkbox */
}

.image-wrapper:hover {
  /* Show the checkbox when the image wrapper is hovered */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

/* Show the checkbox when the image wrapper is hovered */
.image-wrapper:hover .checkbox-container,
.image-wrapper.checked .checkbox-container {
  /* Added class for checked state */
  opacity: 1;
}

/* Optional: Add a slight background to the checkbox to improve visibility over the image */
.checkbox-container .MuiCheckbox-root {
  padding: 0px;
  background: none;
  border-radius: 4px; /* Optional for rounded corners */
}

/* Gradient overlay that will be applied when the image is selected */
.image-wrapper.checked::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#ffffff80, #ffffff80); /* Example gradient */
  z-index: 1; /* Ensure it's below the checkbox but above the image */
  border-radius: 4px; /* Optional for rounded corners */
}

.profile .portfolio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  margin-top: 30px;
}

.profile .portfolio-image {
  width: 230px;
  height: 230px;
  border-radius: 10px;
}

.topappbar-button {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background-color: white;
}

.dashboard-wrapper {
  display: flex;
  justify-content: center;
}

.dashboard .mobile-sidebar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .dashboard .sidebar {
    display: none !important;
  }

  .dashboard .mobile-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    max-width: 90px;
    padding-top: 30px;
  }

  .dashboard .main {
    padding: 10px 0px 10px 0px !important;
  }
}

.dashboard {
  min-height: 500px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  width: 1400px;
  height: 100%;
}

.dashboard .sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  max-width: 260px;
  /* background-color: #f9f9f9; */
  padding-top: 30px;
}

.dashboard .sidebar .sidebar-btn {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 160px;
  height: 30px;
  cursor: pointer;
}

.dashboard .sidebar .switch-wrapper {
  width: 160px;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}

.dashboard .sidebar .switch {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #747686;
}

.dashboard .main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-height: 500px;
  padding: 20px 20px 0px 20px;
  background-color: #ffffff;
}

.dashboard button {
  color: #000000;
  font-family: "Urbanist";
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.dashboard-jobs .no-jobs-text {
  font-family: "Urbanist";
  font-size: 20px;
  font-weight: 200;
  color: #000000;
}

.dashboard-jobs {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

.dashboard-jobs-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
}

.dashboard-btn {
  padding: 10px 15px 10px 15px !important;
  background-color: #000000 !important;
  color: white !important;
  border: none !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  font-family: "Urbanist" !important;
  font-size: 12px !important;
}

.dashboard-btn:hover {
  background-color: #000000d1 !important;
}

.business-new-btn {
  padding: 10px 15px 10px 15px !important;
  background-color: #ffffff !important;
  color: black !important;
  border: none !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  font-family: "Urbanist" !important;
  font-size: 12px !important;
  border: #000000 1px solid !important;
}

.business-new-btn:hover {
  background-color: #f2f2f2d1 !important;
}

.new-post {
  width: 100%;
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
}

.new-post .stepper-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.new-post .step-label {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
}

.new-post .title {
  font-family: "Urbanist";
  font-size: 22px;
  font-weight: 300;
  color: #000000;
  text-align: left;
  margin-bottom: 20px;
}

.new-post .label {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
}

.new-post .footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.new-post .post-button {
  padding: 10px 15px 10px 15px !important;
  background-color: #000000 !important;
  color: white !important;
  border: none !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  font-family: "Urbanist" !important;
  font-size: 16px !important;
  width: 140px;
  margin-top: 20px;
}

.new-post .post-button:hover {
  background-color: #000000d1 !important;
}

.duration-wrapper {
  display: flex;
  gap: 40px;
  align-items: center;
  padding-top: 60px;
  justify-content: space-between;
}

.duration-wrapper .duration-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.duration-wrapper .duration-column .duration-bubble {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  border: #000000 1px solid;
  background-color: #ffffff;
  width: 250px;
  height: 20px;
}

.duration-wrapper .duration {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
  text-align: center;
  width: 100%;
}

.duration-bubble .duration-label-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.duration-bubble .duration-label {
  font-family: "Urbanist";
  font-size: 18px;
  font-weight: 300;
  color: #000000;
}

.duration-bubble .duration-price {
  font-family: "Urbanist";
  font-size: 18px;
  font-weight: 300;
  color: gray;
}

.duration-wrapper .ads {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
}

.duration-wrapper .ads .ads-label-wrapper {
  display: flex;
  gap: 10px;
  background: black;
  height: 50px;
  width: 260px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.duration-wrapper .ads .ads-label {
  font-family: "Urbanist";
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
}

.duration-wrapper .ads .ads-label-price {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 300;
  color: gray;
}

.duration-wrapper .ads .ads-label-rec {
  font-family: "Urbanist";
  font-size: 12px;
  font-weight: 200;
  color: #000000;
}

.duration-wrapper .ads .ads-bubble {
  display: flex;
  gap: 10px;
  align-items: center;
}

.duration-wrapper .ads .ads-label-optional {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 300;
  color: rgb(224, 224, 224);
}

.duration-wrapper .ads .days-label {
  font-family: "Urbanist";
  font-size: 26px;
  font-weight: 200;
  color: #000000;
  margin-top: 10px;
}

.duration-wrapper .discount-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 40px;
  margin-bottom: 20px;
  width: 200px;
}

.duration-wrapper .discount-wrapper .discount-btn {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
  height: 37px;
  margin-left: -6px;
}

.duration-wrapper .discount-wrapper .discount-btn:hover {
  background-color: #1e1e1e;
}

.duration-wrapper .total-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.duration-wrapper .total-price .discount-error {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 500;
  color: #ff6b6b;
  margin-bottom: 10px;
}

.duration-wrapper .total-price .price-amount {
  font-family: "Urbanist";
  font-size: 20px;
  font-weight: 300;
  color: #000000;
}

.checkout-form-wrapper .pay-button {
  padding: 10px 15px 10px 15px !important;
  background-color: #000000 !important;
  color: white !important;
  border: none !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  font-family: "Urbanist" !important;
  font-size: 16px !important;
  width: 140px;
}

.checkout-form-wrapper .pay-button:hover {
  background-color: #000000d1 !important;
}

.checkout-form {
  margin-top: 40px;
  max-width: 800px;
  width: 100%;
  font-family: "Urbanist" !important;
  font-size: 14px !important;
}

.checkout-form-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.ql-snow .ql-picker.ql-size .ql-picker-label,
.ql-snow .ql-picker.ql-size .ql-picker-item {
  font-family: "Urbanist" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(
    data-value
  ) !important; /* Assuming Quill uses data-value attribute */
}

.ql-editor {
  font-family: "Urbanist";
  font-weight: 300;
}

.dashboard-edit-icon {
  background-color: "#fafbfc" !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  padding: 8px !important;
  box-shadow: 0px 0px 8px #00000026 !important;
}

.dashboard-edit-icon:hover {
  background-color: "#fafbfc" !important;
}

@media only screen and (max-width: 600px) {
  .applicant-container {
    display: flex;
    flex-direction: column !important;
  }
}

.applicant-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.applicant {
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #00000026;
  border-radius: 20px;
  width: 220px;
  height: 260px;
  display: flex;
  flex-direction: column;
  padding: 26px;
  align-items: center;
  justify-content: space-between;
}

.applicant .premium-chip {
  padding: 3px 6px 3px 6px;
  background-color: #f5f5f5;
  color: #616161;
  border-radius: 20px;
  font-family: "Urbanist";
  font-size: 10px;
  font-weight: 300;
}

.applicant-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.applicant .promoted {
  padding: 3px 6px 3px 6px;
  background-color: #000000;
  color: white;
  border-radius: 20px;
  font-family: "Urbanist";
  font-size: 10px;
  font-weight: 300;
  margin-bottom: 10px;
  width: 60px;
}

.applicant .applicant-name {
  font-family: "Urbanist";
  font-size: 20px !important;
  font-weight: 600;
  font-size: 14px;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.applicant .applicant-location-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.applicant .applicant-location {
  font-family: "Urbanist";
  font-size: 14px !important;
  font-weight: 200;
  font-size: 14px;
}

.applicant .applicant-date-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.applicant .applicant-date {
  font-family: "Urbanist";
  font-size: 12px !important;
  font-weight: 200;
}

.applicant .applicant-footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 10px;
}

.applicant .applicant-type-container {
  display: flex;
  gap: 10px;
  margin-top: 12px;
}

.applicant .applicant-chip {
  border-radius: 20px;
  font-size: 11px !important;
  font-family: "Urbanist";
  font-weight: 400;
  color: black;
  padding: 5px 10px 5px 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.applicant .applicants-btn {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
}

.applicant .applicants-btn:hover {
  background-color: #000000d1;
}

.applicant button {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
}

.applicant button:hover {
  background-color: #000000d1;
}

.applicant .avatar {
  height: 100px;
  width: 100px;
  box-shadow: 0px 0px 8px #00000026;
  margin-bottom: 10px;
}

.checkout-form {
  width: 100%;
}

.dashboard-settings-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
}

.dashboard-settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: auto;
  background-color: #ffffff;
  align-items: flex-start;
}

.settings-header {
  font-family: "Urbanist";
  font-size: 22px;
  font-weight: 300;
  color: #000000;
}

.settings-label-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
}

.settings-label {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.settings-required {
  font-family: "Urbanist";
  font-size: 10px;
  font-weight: 300;
  color: #000000;
}

.settings-save-button {
  padding: 10px 15px 10px 15px !important;
  background-color: #000000 !important;
  color: white !important;
  border: none !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  font-family: "Urbanist" !important;
  font-size: 16px !important;
  width: 140px;
  margin-top: 20px;
}

.settings-save-button:hover {
  background-color: #000000d1 !important;
}

.messaging {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 20px;
  height: 100%;
}

.avatar-chat {
  width: 45px !important;
  height: 45px !important;
}

.messaging .header {
  font-family: "Urbanist";
  font-size: 26px;
  font-weight: 300;
  color: #000000;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.messaging .msg-sidebar {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 282px;
  height: 520px;
  overflow: auto;
  padding-right: 20px;
}

.messaging .messages-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  height: 100%;
}

.messaging .thread {
  width: 250px;
  height: 50px;
  padding: 14px;
  cursor: pointer;
  border-radius: 20px;
}

.messaging .thread:hover {
  background-color: #f5f5f5 !important;
}

.messaging .thread .name {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-align: left;
}

.messaging .thread .message {
  font-family: "Urbanist";
  font-size: 12px;
  font-weight: 200;
  color: #000000;
  margin-top: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messaging .thread .time {
  font-family: "Urbanist";
  font-size: 12px;
  font-weight: 200;
  color: gray;
  margin-top: 10px;
  text-align: left;
}

.messaging .messages-container .header {
  display: flex;
  gap: 10px;
  font-family: "Urbanist";
  font-size: 24px;
  font-weight: 200;
}

.messaging .messages-container .body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-top: 40px;
}

.messaging .messages-container .body .message-bubble {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
  text-align: left;
  padding: 14px;
}

.messaging .messages-container .body .message-bubble .message-text {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 300;
}

.messaging .messages-container .body .message-bubble .message-time {
  font-family: "Urbanist";
  font-size: 12px;
  font-weight: 200;
}

.messaging .left {
  background-color: #f5f5f5;
  border-radius: 20px;
  color: #000000;
  margin-right: auto !important;
}

.messaging .right {
  background-color: #000000;
  border-radius: 20px;
  color: white;
  margin-left: auto !important;
}

.messaging .messages-footer {
  width: auto;
  background-color: #eeeef8;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
}

.messaging .messages-footer input[type="text"] {
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 20px;
  font-family: "Urbanist";
  font-size: 14px;
  background-color: #eeeef8;
}

.messaging .messages-footer input[type="text"]:focus {
  outline: none;
  border: none;
}

.edit-post {
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
}

.edit-post .title {
  font-family: "Urbanist";
  font-size: 22px;
  font-weight: 300;
  color: #000000;
  text-align: left;
  margin-bottom: 20px;
}

.edit-post .label {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
}

.edit-post .footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.edit-post .post-button {
  padding: 10px 15px 10px 15px !important;
  background-color: #000000 !important;
  color: white !important;
  border: none !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  font-family: "Urbanist" !important;
  font-size: 16px !important;
  width: 140px;
  margin-top: 20px;
}

.edit-post .post-button:hover {
  background-color: #000000d1 !important;
}

.edit-modal-wrapper .days-left-title {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
  margin-top: 20px;
}

.edit-modal-wrapper .duration-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.edit-modal-wrapper .duration-column .duration-bubble {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  border: #000000 1px solid;
  background-color: #ffffff;
  width: 250px;
  height: 20px;
}

.edit-modal-wrapper .duration {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
  text-align: center;
  width: 100%;
}

.duration-bubble .duration-label-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.duration-bubble .duration-label {
  font-family: "Urbanist";
  font-size: 18px;
  font-weight: 300;
  color: #000000;
}

.duration-bubble .duration-price {
  font-family: "Urbanist";
  font-size: 18px;
  font-weight: 300;
  color: gray;
}

.edit-modal-wrapper .ads {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
  margin-top: 20px;
}

.edit-modal-wrapper .ads .ads-label-wrapper {
  display: flex;
  gap: 10px;
  background: black;
  height: 50px;
  width: 260px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.edit-modal-wrapper .ads .ads-label {
  font-family: "Urbanist";
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
}

.edit-modal-wrapper .ads .ads-label-price {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 300;
  color: gray;
}

.edit-modal-wrapper .ads .ads-label-rec {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  margin-top: 10px;
}

.edit-modal-wrapper .ads .ads-bubble {
  display: flex;
  gap: 10px;
  align-items: center;
}

.edit-modal-wrapper .ads .ads-label-optional {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 300;
  color: rgb(224, 224, 224);
}

.edit-modal-wrapper .ads .days-label {
  font-family: "Urbanist";
  font-size: 26px;
  font-weight: 200;
  color: #000000;
  margin-top: 10px;
}

.edit-modal-wrapper .discount-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 40px;
  margin-bottom: 20px;
  width: 200px;
}

.edit-modal-wrapper .discount-wrapper .discount-btn {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
  height: 37px;
  margin-left: -6px;
}

.edit-modal-wrapper .discount-wrapper .discount-btn:hover {
  background-color: #1e1e1e;
}

.edit-modal-wrapper .total-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.edit-modal-wrapper .total-price .price-amount {
  font-family: "Urbanist";
  font-size: 20px;
  font-weight: 300;
  color: #000000;
}

.edit-modal-wrapper .edit-modal-clear-btn {
  padding: 4px 7px 4px 7px;
  background-color: #f5f5f5;
  color: #616161;
  border-radius: 20px;
  font-family: "Urbanist";
  font-size: 12px;
  font-weight: 300;
  width: 60px;
  border: none;
  cursor: pointer;
}

.edit-modal-wrapper .edit-modal-clear-btn:hover {
  background-color: #e2e2e2;
}

.billing-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 20px;
}

.billing-container .table-label {
  font-family: "Urbanist";
  font-size: 18px;
  font-weight: 300;
  color: #000000;
  width: 200px;
  text-align: left;
  padding-left: 10px;
}

.billing-container .table-row {
  display: flex;
  flex-direction: row;
}

.billing-container .item-label {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 200;
  color: #000000;
  text-align: left;
  width: 200px;
  padding-left: 10px;
}

.plans-container {
  display: flex;
  gap: 20px;
  width: auto;
  align-items: center;
}

.plans-container .card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 200px;
  height: 140px;
  padding: 20px;
  box-shadow: 0px 0px 8px #00000026;
  justify-content: space-between;
}

.plans-container .card-title-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.plans-container .card-title {
  font-family: "Urbanist";
  font-size: 30px;
  font-weight: 500;
  color: #000000;
}

.plans-container .card-subtitle {
  font-family: "Urbanist";
  font-size: 20px;
  font-weight: 300;
  color: gray;
}

.plans-container .price-option-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.plans-container .price-option {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  text-align: left;
  display: flex;
  gap: 10px;
  align-items: center;
}

.plans-container .card-business .price-option {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
}

.plans-container .current-button {
  padding: 10px 15px 10px 15px;
  background-color: #ffffff;
  color: black;
  border: 1px solid #000000;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
}

.plans-container .upgrade-button {
  padding: 10px 15px 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Urbanist";
  font-size: 12px;
}

.plans-container .upgrade-button:hover {
  background-color: #000000d1;
}

.cancel-label {
  font-family: "Urbanist";
  font-size: 14px;
  font-weight: 200;
  color: #000000;
}

.aboutus {
  display: flex;
  flex-direction: row;
  gap: 100px;
  justify-content: center;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
}

.aboutus .title {
  font-family: "Urbanist";
  font-size: 40px;
  font-weight: 600;
  color: #000000;
  width: 300px;
  text-align: left;
}

.aboutus .content {
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 300;
  color: #000000;
  text-align: left;
  letter-spacing: 3px;
}

.aboutus .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 500px;
}
